$base-color: #4ec0b0;
$contrast-color: #FF6C5C;
$input-error-color: #FF5252;

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

/*@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);*/

@import "resources/assets/sass/text.scss";
@import "resources/assets/sass/helpers.scss";
@import "resources/assets/sass/buttons.scss";
@import "resources/assets/sass/forms.scss";

a, a:link, a:hover, a:visited {
  color: $base-color;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  color: #666;
  background: #181D22;
}

section.content {
  padding: 40px 0;
  background: #fff;
}

footer.footer {
  padding: 40px 0;
  color: #fff;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

.arrow {
  bottom: 10px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
}

.arrow > a {
  background: url(/img/arrow-down.svg) center no-repeat;
  background-size: 20px;
  color: #fff;
  display: block;
  height: 30px;
  width: 40px;
}

.bounce {
  -webkit-animation: pulse 1s 4;
  animation: pulse 1s 4;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.site-header {
  /*height: 100vh;
  min-height: 622px;*/
  padding: 40px 0;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background: #2b4959;
  background-image: linear-gradient(135deg, #464672,#2b4959 40%,#2b4959);
}

.partners {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

/**
* ##############################################################
* Error messages
* ##############################################################
*/
.input-error {
  display: none;
  position: absolute;
  top: -40px;
  border-radius: 2px;
  font-size: 14px;
  background: $input-error-color;
}

.input-error > .error-arrow {
  position: absolute;
  left: 7px;
  bottom: -7px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 7px solid $input-error-color;
}

.input-error > a.error-close,
.input-error > a.error-close:hover {
  float: right;
  padding: 0 7px 0 7px;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
}

.input-error > .error-inner {
  float: left;
  padding: 5px 7px 5px 7px;
}

.form-group.error .input-error {
  display: block;
}

.btn > .ajax-loader {
  display: none;
  float: left;
  width: 16px;
  height: 11px;
  margin-top: 4px;
  margin-right: 10px;
  background-image: url(/img/ajax-btn-loader-white.gif);
}

/**
* ##############################################################
* Bouncing Arrow
* ##############################################################
*/

/*
@import "compass/css3";

@include keyframes(bounce) {
  0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-30px));
  }
  60% {
    @include transform(translateY(-15px));
  }
}

.arrow {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left:-20px;
  width: 40px;
  height: 40px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
}

.bounce {
  @include animation(bounce 2s infinite);
}
*/

/**
* ##############################################################
* Navbar reset
* ##############################################################
*/

.navbar {
  min-height: 80px;
  border-bottom: 1px dashed #317485;
}

.navbar a {
  color: #fff;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
  color: $base-color;
}

.navbar-brand {
  padding: 0 15px;
  color: #fff;
  line-height: 40px;
}

.navbar-brand > img {
  float: left;
  margin-right: 10px;
}

.navbar-brand > .name {
  float: left;
}

.navbar-toggle {
  border-radius: 0;
  background-color: $base-color;
}
.navbar-toggle .icon-bar {
  background-color: #fff;
}

/**
* ##############################################################
* Offers
* ##############################################################
*/

.offers > .offer {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

.offers > .offer:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.offers > .offer > .body > h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.offers > .offer > .body > .category {
  margin-bottom: 10px;
  font-size: 12px;
  color: #999;
}

.offers > .offer > .body > .heading {
  font-size: 20px;
}

.offers > .offer > .body > .description {
  margin-top: 10px;
}

.offers > .offer > .body > .btn {
  margin-top: 20px;
}

.single-offer > .left {
  float: none;
  width: 100%;
  font-size: 18px;
  line-height: 1.6;
  color: #999;
}

.single-offer > .left > h2 {
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: $base-color;
  border-bottom: 1px solid #ddd;
}

h2 > span {
  padding-bottom: 8px;
  border-bottom: 1px solid $base-color;
}

.single-offer > .left > h3 {
  margin: 20px 0;
  color: #999;
}

.single-offer > .right {
  float: none;
  width: 100%;
  margin-top: 40px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.single-offer > .right > .logo {
  padding: 20px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.single-offer > .right > .logo > img {
  display: inline;
  max-width: 100%;
  height: auto;
}

.single-offer > .right > .offer-box {
  padding: 20px;
  border-top: 1px solid #ddd;
}

.single-offer > .right > .offer-box h2 {
  margin-top: 0;
  font-size: 20px;
}

.offer-box .error {
  font-size: 14px;
  color: #a94442;
}

.offer-box.hidden-message {
  color: #41A2D8;
  background: #DFF1FB;
}

.single-offer p:last-child {
  margin-bottom: 0;
}

.single-offer > .right > a.link {
  display: block;
  font-size: 18px;
  text-decoration: none;
}

.single-offer > .right > a.link:hover {
  text-decoration: none;
  background: #e6f7f4;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .nav > li > a {
    padding: 10px 15px;
    font-size: 14px;
  }

  .single-offer > .left {
    float: left;
    width: 440px;
  }

  .single-offer > .right {
    margin-top: 0;
    float: right;
    width: 240px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .single-offer > .left {
    float: left;
    width: 600px;
  }

  .single-offer > .right {
    float: right;
    width: 300px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .single-offer > .left {
    float: left;
    width: 750px;
  }

  .single-offer > .right {
    float: right;
    width: 350px;
  }
}
