/**
* ##############################################################
* Headings
* ##############################################################
*/

.site-header h1 {
  margin-top: 0;
  font-size: 60px;
  font-weight: 200;
}

.site-header h1 a {
  color: #fff;
  text-decoration: none;
}

.site-header h2 {
  font-weight: 200;
}

.text-center-heading {
  height: 13px;
  margin: 40px 0;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.text-center-heading > span {
  padding: 0 20px;
  background: #fff;
}

.site-header .text-center-heading {
  color: #fff;
  border-bottom: 1px dashed #317485;
}

.site-header .text-center-heading > span {
  padding: 0 20px;
  background: #2b4959;
}
