label {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.form-group .loader {
  display: none;
  position: absolute;
  right: 32px;
  width: 32px;
  height: 46px;
  background-image: url("/img/ajax-loader-32.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.loader.show {
  display: block;
}

.modal-footer .checkbox {
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 14px;
}

.modal-footer label {
  text-transform: none;
}
