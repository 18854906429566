.text-italic {
  font-style: italic;
}

.text-normal {
  font-weight: normal;
}

.text-medium-bold {
  font-weight: 500;
}

.text-bold {
   font-weight: 700;
 }

.text-green {
  color: #4ec0b0;
}

.text-red {
  color: #FF6C5C;
}

.img-desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.img-border-light-grey {
  box-shadow: 0px 0px 0px 1px #ddd;
}
